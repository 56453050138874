.dataExport {
  width: 100px;
  text-align: center;
  padding: 10px;
  background: rgb(124, 16, 167);
  border-radius: 5px;
  float: right;
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
}
