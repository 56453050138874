@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700;800&family=Oswald:wght@200&family=Roboto+Slab:wght@300&display=swap);
.admin {
  font-family: Oswald;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #65dabc;
  text-align: center;
}
.logout {
  display: flex;
  margin: auto;
  text-align: center;
  justify-content: center;
}
.logout p {
  font-family: Oswald;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.logout_Svg {
  width: 24px;
  padding-left: 10px;
  cursor: pointer;
}
.list p {
  font-family: "Roboto Slab";
  font-size: 35px;
  font-weight: 400;
  line-height: 0px;
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .list p {
    line-height: 40px;
  }
}

/* .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer {
  justify-content: center;
}

.MuiTabs-flexContainer.css-k008qs {
  justify-content: center;
} */
.tabLabel {
  font-family: "Montserrat";
  font-size: 13px;
  font-weight: 800;
  padding: 10px;
  line-height: 0px;
}
span.MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {
  height: 0px;
}
.itemOne{
  border: 1px solid;
  color: grey;
  height: 20px;
  margin: 12px;
}


@-webkit-keyframes shake {
    0% { -webkit-transform: translateX(0); transform: translateX(0); }
    20% { -webkit-transform: translateX(-10px); transform: translateX(-10px); }
    40% { -webkit-transform: translateX(10px); transform: translateX(10px); }
    60% { -webkit-transform: translateX(-10px); transform: translateX(-10px); }
    80% { -webkit-transform: translateX(10px); transform: translateX(10px); }
    100% { -webkit-transform: translateX(0); transform: translateX(0); }
  }
  @keyframes shake {
    0% { -webkit-transform: translateX(0); transform: translateX(0); }
    20% { -webkit-transform: translateX(-10px); transform: translateX(-10px); }
    40% { -webkit-transform: translateX(10px); transform: translateX(10px); }
    60% { -webkit-transform: translateX(-10px); transform: translateX(-10px); }
    80% { -webkit-transform: translateX(10px); transform: translateX(10px); }
    100% { -webkit-transform: translateX(0); transform: translateX(0); }
  }
  .scale-in-out {
    -webkit-animation: scale-in-out 0.2s ease-in-out forwards;
            animation: scale-in-out 0.2s ease-in-out forwards;
  }
  
  @-webkit-keyframes scale-in-out {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }
    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  
  @keyframes scale-in-out {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }
    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  
.animated-chip {
    -webkit-animation-name: scale-in;
            animation-name: scale-in;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }
  
  @-webkit-keyframes scale-in {
    from {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }
    to {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  
}
  
  @keyframes scale-in {
    from {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }
    to {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  
}
.scale-in-out {
    -webkit-animation: scale-in-out 0.2s ease-in-out forwards;
            animation: scale-in-out 0.2s ease-in-out forwards;
  }
  
  @-webkit-keyframes scale-in-out {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }
    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  
  @keyframes scale-in-out {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }
    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  .custom-chip-exit-active {
    -webkit-animation: dust 1s linear;
            animation: dust 1s linear;
  }
  
  @-webkit-keyframes dust {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
      opacity: 1;
    }
    50% {
      -webkit-transform: translate(5px, -20px);
              transform: translate(5px, -20px);
      opacity: 0.5;
    }
    100% {
      -webkit-transform: translate(10px, -40px);
              transform: translate(10px, -40px);
      opacity: 0;
    }
  }
  
  @keyframes dust {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
      opacity: 1;
    }
    50% {
      -webkit-transform: translate(5px, -20px);
              transform: translate(5px, -20px);
      opacity: 0.5;
    }
    100% {
      -webkit-transform: translate(10px, -40px);
              transform: translate(10px, -40px);
      opacity: 0;
    }
  }
  
.text {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 700;
  line-height: 0px;
}
.shibSvg {
  height: 20px;
  width: 32px;
}
.top_user_text {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
}
.icon {
  text-align: center;
  padding-top: 52px;
  height: 144px;
}
.icon span {
  margin: auto;
  font-family: Montserrat;
  font-size: 36px;
  font-weight: 700;
  line-height: 60px;
}
.categories {
  display: grid;
}
.timeTrend h3 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}
.experts_heading h3 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
}
.btn {
  /* height: 31px; */
  width: 252px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  /* line-height: 15px; */
  justify-content: center;
  text-align: center;
  margin: auto;
}

.text {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 700;
  line-height: 0px;
}
.top_cateogory_icon {
  height: 35px;
  width: 59px;
  font-family: Fugaz One;
  font-size: 32px;
  font-weight: 400;
  line-height: 51px;
  letter-spacing: 0em;
  text-align: center;
}
.total_user_top{
  font-family: Montserrat;
font-size: 14px;
font-weight: 500;
line-height: 17px;
letter-spacing: 0em;
text-align: left;

}

.total_top_user {
  height: 35px;
  width: 67px;
  font-family: "Montserrat";
  letter-spacing: 0em;
  text-align: center;
}
.top_user_text {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
}
.top_cateogory_text {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 700;
  line-height: 0px;
  padding: 11px 0px 0px 6px;
}

.icon span {
  margin: auto;
  font-family: Montserrat;
  font-size: 36px;
  font-weight: 700;
  line-height: 60px;
}
.categories {
  display: grid;
}

.btn {
  height: 31px;
  width: 252px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  justify-content: center;
  text-align: center;
  margin: auto;
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.radial-progress-bar {
  background-color: rgb(214, 208, 208);
  width: 5rem;
  height: 5rem;
  margin-bottom: 2rem;
  position: relative;
  border-radius: 50%;
  background-image: linear-gradient(
    34.59deg,
    #4cc9f0 12.55%,
    #4895ef 12.74%,
    #560bad 30.39%,
    #b5179e 46.54%,
    #f72585 52.34%,
    transparent -1%
  );
  animation: rotate 1s linear infinite;
  -webkit-animation: rotate 1s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
}

.radial-progress-bar .overlay {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  background: #fff;
  border-radius: 50%;
}

.spinner-text {
  color: purple;
  font-family: "Montserrat", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
}
.admin {
  font-family: Oswald;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #65dabc;
  text-align: center;
}
.logout {
  display: flex;
  margin: auto;
  text-align: center;
  justify-content: center;
}
.backbtn {
  text-align: center;
}
.add-space {
  height: 80px;
}
.back_btn {
  text-align: center;
  justify-content: center;
  margin-left: 47px;
}
.user_name {
  text-align: center;
  font-family: Roboto Slab;
  font-size: 35px;
  font-weight: 400;
  line-height: 46px;
}
.bottm_space {
  height: 100px;
}
.details {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

@media (max-width: 480px) {
  .MuiGrid-root.MuiGrid-container.css-11lq3yg-MuiGrid-root {
    padding-left: 0px;
  }
}

.table_row {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}
.table_data {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}
.Expertise {
  padding-top: 50px;
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.datepicker {
  text-align: end;
  margin-right: 15px;
}
.date-picker-alignment {
  display: inline-flex;
  margin-top: 30px;
  margin-bottom: 30px;
}
.submit-btn-for-dates {
  background-color: #7c10a7;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  text-align: center;
  margin: auto;
  color: rgb(170, 167, 167);
  font-size: 15px;
  font-weight: 500;
  font-family: "Montserrat";
  color: white;
  margin-left: 20px;
  padding: 8px;
}

.submit-btn-for-dates:disabled {
  background-color: #b771d3;
  color: white;
}
.reset-btn-for-dates {
  background-color: #7c10a7;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  text-align: center;
  margin: auto;
  color: white;
  font-size: 15px;
  font-weight: 500;
  font-family: "Montserrat";
  margin-left: 20px;
  padding: 8px;
}

.reset-btn-for-dates:disabled {
  background-color: #b771d3;
  color: white;
}
.submit-date-text {
  width: 58px;
}
.reset-date-text {
  width: 58px;
}

.gender-percentage-charts {
  overflow-y: hidden;
}
.user-count-by-vote-and-post-charts {
  overflow-y: hidden;
}
.venn_Charts {
  position: relative;
  left: 50px;
  color: white;
}
.both-post-vote-user-tatal-count {
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 500;
  text-align: center;
}

.box-container {
  display: flex;
  justify-content: center;
}

/* @media only screen and (max-width: 600px) {
.box{
  width: 20.7px;
}
  
} */
.box1 {
  background-color: #2f8dcf;
  width: 250.7px;
  height: 120px;
  margin: 18px;
  border-radius: 5px;
}
.box2 {
  background-color: #ff7300;
  width: 250.7px;
  height: 120px;
  margin: 18px;
  border-radius: 5px;
}

.box3 {
  background-color: #800080;
  width: 250.7px;
  height: 120px;
  margin: 18px;
  border-radius: 5px;
}

.box4 {
  background-color: #f02626;
  width: 250.7px;
  height: 120px;
  margin: 18px;
  border-radius: 5px;
}
.loading-title {
  font-family: "Montserrat";
  font-weight: 500;
  color: white;
  font-size: 20px;
}

.textual-heading-text {
  font-family: "Montserrat";
  font-weight: 500;
  color: white;
  padding-left: 10px;
  font-size: 15px;
  margin-top: 25px;
}
@media only screen and (max-width: 600px) {
  .textual-heading-text {
    font-family: "Montserrat";
    font-weight: 500;
    color: white;
    font-size: 11px;
    margin-top: -8px;
    width: 38px;
  }
}
.textual-heading-Total-text {
  font-family: "Montserrat";
  font-weight: 500;
  color: white;
  padding-left: 10px;
  font-size: 15px;
  margin-top: 25px;
}
@media only screen and (max-width: 600px) {
  .textual-heading-Total-text {
    font-family: "Montserrat";
    font-weight: 500;
    color: white;
    font-size: 8px;
    padding-left: 0px;

    margin-top: 8px;
    width: 38px;
  }
}

.textual-heading-data {
  margin-top: 10px;
  font-size: 30px;
  font-family: "Montserrat";
  font-weight: 600;
  color: white;
  padding-left: 10px;
}
@media only screen and (max-width: 600px) {
  .textual-heading-data {
    font-size: 15px;
    font-family: "Montserrat";
    font-weight: 600;
    color: white;
  }
}

.total-days {
  position: relative;
  left: 5%;
  bottom: 0%;
  font-size: 10px;
  font-family: "Montserrat";
  color: white;
}

.Apex.fill.colors{
    color:"black"
  } 
.top-user-heading{
  font-family: "Montserrat";
  margin: 19px;
  font-size: 15px;
  font-weight: 500;
}
@media only screen and (max-width: 600px) {
  .charts {
    overflow-y: hidden;
    position: relative;
    margin-left: -30px;
    right: 0%;
    top: 10px;
  }
}



.average-data-count {
  font-size: 30px;
  font-family: "Montserrat";
  font-weight: 600;
}

.stats-typography {
  text-align: center;
  width: 50%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.average-data-heading {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 15px;
}

.average-data-heading-last {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 15px;
}
@media only screen and (max-width: 600px) {
  .summary-alignment {
    height: 55%;
  }
}
@media only screen and (max-width: 1200px) {
  .summary-alignment {
    height: 75%;
  }
}

@media only screen and (max-width: 1500px) {
  .summary-alignment {
    height: 92%;
  }
}
@-webkit-keyframes shake {
  0% { -webkit-transform: translateX(0); transform: translateX(0); }
  20% { -webkit-transform: translateX(-10px); transform: translateX(-10px); }
  40% { -webkit-transform: translateX(10px); transform: translateX(10px); }
  60% { -webkit-transform: translateX(-10px); transform: translateX(-10px); }
  80% { -webkit-transform: translateX(10px); transform: translateX(10px); }
  100% { -webkit-transform: translateX(0); transform: translateX(0); }
}
@keyframes shake {
  0% { -webkit-transform: translateX(0); transform: translateX(0); }
  20% { -webkit-transform: translateX(-10px); transform: translateX(-10px); }
  40% { -webkit-transform: translateX(10px); transform: translateX(10px); }
  60% { -webkit-transform: translateX(-10px); transform: translateX(-10px); }
  80% { -webkit-transform: translateX(10px); transform: translateX(10px); }
  100% { -webkit-transform: translateX(0); transform: translateX(0); }
}
.scale-in-out {
  -webkit-animation: scale-in-out 0.2s ease-in-out forwards;
          animation: scale-in-out 0.2s ease-in-out forwards;
}

@-webkit-keyframes scale-in-out {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
  }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

@keyframes scale-in-out {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
  }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}



.text {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 700;
  line-height: 0px;
}
.user-profile {
  height: 32px;
  width: 32px;
  object-fit: cover;
  border-radius: 8px;
  
}
.top_user_text {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
  margin: 15px 0px 0px 24px

}
.icon {
  text-align: center;
  padding-top: 52px;
  height: 144px;
}
.icon span {
  margin: auto;
  font-family: Montserrat;
  font-size: 36px;
  font-weight: 700;
  line-height: 60px;
}
.categories {
  display: grid;
}

.btn {
  height: 31px;
  width: 252px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  justify-content: center;
  text-align: center;
  margin: auto;
}

.text {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 700;
  line-height: 0px;
}
.top_cateogory_icon {
  height: 35px;
  width: 59px;
  font-family: Fugaz One;
  font-size: 32px;
  font-weight: 400;
  line-height: 51px;
  letter-spacing: 0em;
  text-align: center;
}
.total_user_top{
  font-family: Montserrat;
font-size: 14px;
font-weight: 500;
line-height: 17px;
letter-spacing: 0em;
text-align: left;

}

.total_top_user {
  height: 35px;
  width: 67px;
  font-family: "Montserrat";
  letter-spacing: 0em;
  text-align: center;
}
.top_user_text {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
}
.top_cateogory_text {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 700;
  line-height: 0px;
  padding: 11px 0px 0px 6px;
}

.icon span {
  margin: auto;
  font-family: Montserrat;
  font-size: 36px;
  font-weight: 700;
  line-height: 60px;
}
.categories {
  display: grid;
}

.btn {
  height: 31px;
  width: 252px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  justify-content: center;
  text-align: center;
  margin: auto;
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.radial-progress-bar {
  background-color: rgb(214, 208, 208);
  width: 5rem;
  height: 5rem;
  margin-bottom: 2rem;
  position: relative;
  border-radius: 50%;
  background-image: linear-gradient(
    34.59deg,
    #4cc9f0 12.55%,
    #4895ef 12.74%,
    #560bad 30.39%,
    #b5179e 46.54%,
    #f72585 52.34%,
    transparent -1%
  );
  animation: rotate 1s linear infinite;
  -webkit-animation: rotate 1s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
}

.radial-progress-bar .overlay {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  background: #fff;
  border-radius: 50%;
}

.spinner-text {
  color: purple;
  font-family: "Montserrat", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
}
.cateogory-leaderboard-counts {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
  margin-left: 83px;
}
.Category-leaderboard-heading {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  padding-left: 56px;
}

.Category-leaderboard-heading1 {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  padding-left: 65px;
}

.view_btn {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 700;
  
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.email {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
}

.heading {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
}

.ban_btn {
  height: 30px;
}


.ban_btns {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 700;
  color: white;
}

.ban_btn p {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 700;
  color: white;
}

.unBan_btn {
  height: 30px;
}

.unBan_btn p {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 700;
  color: white;
}

ul.MuiPagination-ul.css-wjh20t-MuiPagination-ul {
  text-align: center;
  justify-content: center;
}

ul.MuiPagination-ul {
  justify-content: center;
}

button.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-page.MuiPaginationItem-rounded.MuiPaginationItem-textSecondary.Mui-selected {
  background-color: rgb(124, 16, 167);
}
.search-button{
  border: 1px solid grey;
  border-radius: 5px;
  width: 30%;
}
.dataExport {
  width: 100px;
  text-align: center;
  padding: 10px;
  background: rgb(124, 16, 167);
  border-radius: 5px;
  float: right;
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
}

